import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Location } from '@reach/router'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { addGoogleAnalyticsPageData } from '../../utils/googleAnalyticsEvents'

const TermsConditions = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Location>
      {({ location }) => (
        <Layout location={location} title={siteTitle} lang={'ar'}>
          <h1 className="customH1">{'الشروط والأحكام'}</h1>
          <SEO
            title="الشروط والأحكام | الشرق للأخبار"
            description="الشروط والأحكام الخاصة بموقع الشرق للأخبار وقوانين استخدام المعلومات المنشورة في الموقع والاستخدام الأمثل للمحتوى المقدم لك وشروط استخدام محتوى الموقع."
            meta={[
              {
                property: `keywords`,
                content: `الشرق للاخبار، اخبار اليوم، موقع اخبار ، موقع للآخبار، موقع اخباري ، معلومات الاخبار ، محتوى اخباري`
              }
            ]}
            lang="ar"
            canonicalUrl={`${process.env.GATSBY_BASE_URL}ar/terms-conditions/`}
          />
          <div className="small-print-page">
            <h2>الموقع والخدمات</h2>

            <p>
              تحدد شروط وأحكام الاستخدام (الشروط) هذه المعلومات الهامة عن
              المواقع والتطبيقات على الهواتف وغيرها من الخدمات الإلكترونية
              والرقمية (الخدمات) التي تُشغلها الشرق للخدمات الإخبارية المحدودة
              ومقرها جايت فيلادج 11، الطابق الرابع، مركز دبي المالي العالمي،
              ص.ب. 507159، دبي، الإمارات العربية المتحدة (وهذا المقصود بعبارة
              نحن ولنا). ويقع موقعنا الأساسي على عنوان .... (الموقع). و"أنت"
              تشير إليك أنت كمستخدم للموقع و/أو الخدمات (وهذا المقصود بعبارة لك)
              والإشارة إلى المستخدمين تعني مستخدمي الموقع و/أو الخدمات، من
              الأفراد أو الجماعات حسب ما يقتضيه السياق.
            </p>

            <p>
              يرجى قراءة الشروط بعناية لأنها تؤثر على حقوقك ومسؤولياتك بموجب
              القانون. إن لم توافق على الشروط، يرجى عدم النفاذ إلى الموقع أو
              الخدمات ولا استخدامها. من خلال النفاذ إلى الموقع أو الخدمات، أنت
              توافق على الالتزام بالشروط، التي تنطبق بغض النظر عن وسيلة ومكان
              النفاذ إلى الموقع والخدمات، مباشرة أو بشكل غير مباشر.{' '}
            </p>

            <p>
              كما يخضع استخدامك للموقع والخدمات إلى سياسية الكوكيز والخصوصية
              التي تتضمن معلومات عن كيفية استخدام معلوماتك الشخصية وحقوقك ذات
              العلاقة. وضعت سياسة الكوكيز والخصوصية{' '}
              <Link to="/ar/privacy-policy">هنا</Link> ونشجعك على قراءتها قبل
              النفاذ إلى الخدمات أو استخدامها.
            </p>

            <p>
              نقوم بإدخال بعض التغييرات على هذه الشروط في أي وقت. واستمرارك في
              استخدام الموقع أو الخدمات بعد إدخال التغييرات على الشروط يعتبر
              قبولاً للتغييرات هذه. إن النفاذ إلى الموقع أو الخدمات ليس مضموناً
              وقد نختار أن تدخل تغيراً مؤقتاً أو دائماً أو إجراء تحديث أو سحب أو
              عدم توفير النفاذ جزئياً أو كاملاً، من دون تحمّل أية مسؤولية.
            </p>

            <p>
              إذا قمنا في أي وقت بتنظيم مسابقة عبر الموقع أو الخدمات، وترغب في
              المشاركة في هكذا مسابقة، يجب أن تؤكد لدى دخولك المسابقة أنك فوق
              السن القانونية للمشاركة وتوافق على الامتثال إلى القواعد والشروط
              والأحكام الخاصة بالمسابقة.
            </p>

            <p>
              إذا كان هناك حاجة للتسجيل للنفاذ إلى أي جزء من الموقع أو الخدمات،
              قد يتعين عليك إعطاء تفاصيل مثل اسم المستخدم وكلمة السر وبيانات
              الاتصال. في هذه الحالة، سوف تكون مسؤولاً عن الأمن وحسن استخدام
              بياناتك ويتعين ألا تفصح عنها لأي طرف ثالث. يجب أن تغيّر كلمة السر
              فوراً إذا ما شكيت باختراقها. يجب ألا تنشئ أكثر من حساب شخصي واحد.
              نحتفظ بحق إلغاء أو تقييد حسابك أو استخدامك للموقع أو الخدمات فوراً
              ومن دون سابق إنذار أو تحمل أية مسؤولية عن ذلك، إذا ما ارتأينا أنك
              خالفت هذه الشروط أو لم تحترم أي قانون أو قواعد أو أنظمة أو قمت بأي
              سلوك آخر غير ملائم أو لأي سبب آخر خاص بالأعمال.
            </p>

            <h2>القاصرون</h2>
            <p>
              يجب ألا يقوم القاصرون أو من ليس لديهم الأهلية الكاملة بالنفاذ إلى
              الموقع أو الخدمات من دون إذن أولياء الأمور أو الوصي القانوني
              عليهم. وتقع على عاتق أولياء الأمور والأوصياء القانونيين لمن هو دون
              سن الثامنة عشر أو يفتقد إلى الأهلية الكاملة مسؤولية مراجعة هذه
              الشروط وتحديد إذا ما كان هذا الموقع وهذه الخدمات مناسبة أم لا وإذا
              كان بإمكان هذا الشخص النفاذ إليها واستخدامها.
            </p>

            <h2>المحتوى</h2>
            <p>
              تبقى كافة الحقوق، على سبيل المثال لا الحصر، حق المؤلف وحقوق قاعدة
              البيانات والعلامة التجارية للموقع والخدمات ومحتواها أكان سمعياً أو
              صوراً أو فيديو أو بأي شكل آخر (المحتوى) وفي كل وقت معنا أو مع
              الجهات المرَخِصة لنا. يسمح لك باستخدام المحتوى هذا فقط بإذن صريح
              ومكتوب منا أو من الجهة المرَخِصة لنا وحسب ما هو ضروري حصراً
              لاستخدامك الشخصي والخاص وغير التجاري. لن تقوم ولن تساعد أو تسهل
              على أي طرف ثالث عملية نسخ أو إعادة إنتاج أو تنزيل أو نقل أو توزيع
              أو استغلال تجارياً أو وضع أعمال مستمدة من هذا المحتوى. وإذا ما
              علمت بمثل هذا الاستخدام، توافق على إخطارنا فوراً.{' '}
            </p>

            <p>
              لا يمكنك إجراء تعديل على أي محتوى أو حذف الإشارات إلى حقوق المؤلف
              أو أية حقوق ملكية أخرى من المحتوى. لا يمكن تنزيل أي محتوى وإعادة
              تحميله على موقع آخر أو تطبيق أو خدمة أكان بلغته الأصلية أو
              مترجماً. يمكن أن تضع رابطاً على ملكك أنت (ولكن ليس على ملك أي شخص
              آخر) لموقعنا أو خدماتنا طالما أن ذلك يجري بطريقة منصفة وقانونية
              ولا يلحق الأذى بسمعتنا لكن يجب ألا تضع الرابط بطريقة توحي بأي نوع
              من التعاون أو الدعم من قبلنا، إن لم يكن موجوداً فعلاً.
            </p>

            <p>
              المحتوى المعروض على الموقع أو الخدمات يقدّم من دون أية تعهد أو شرط
              أو ضمان لدقته أو اكتماله. لن نتحمل أية مسؤولية عن المحتوى أو الدقة
              أو التوقيت أو موثوقية المعلومات أو التصريحات في الموقع أو الخدمات
              أو عن التصريحات أو النصائح و/أو الآراء التي يعطيها المستخدمون بما
              في ذلك على سبيل المثال لا الحصر على أية ألواح أو منتديات نقاشية.
              إذا كان لديك أية شكوى من أفعال أو تصريحات أي مستخدم آخر، أنت توافق
              على التقدم بالشكوى ضد المستخدم نفسه فقط وليس ضدنا.
            </p>

            <p>
              أنت تقر وتوافق على أننا غير مسؤولين عن توفر أو عن محتوى مواقع أو
              خدمات أو مواد من أطراف ثالثة قد تنفذ إليها من خلال وعبر الموقع أو
              الخدمات بما في ذلك الروابط الموجودة فيها. نحن لا ندعم ولن نتحمل
              أية مسؤولية عن أي محتوى أو إعلان أو منتجات أو خدمات على موقع
              وخدمات طرف ثالث أو متوفرة من خلالها.{' '}
            </p>

            <p>
              علاوة على ذلك، قد يتضمن الموقع والخدمات إعلانات من طرف ثالث. كل
              معلن هو مسؤول حصراً عن المادة الإعلانية الخاصة به. لا نتحمل أية
              مسؤولية عن (ولا نمثل ولا ندعم) أية مادة إعلامية.
            </p>

            <h2>المادة المقدمة</h2>
            <p>
              عند نشر أو تقديم أي محتوى أو مساهمات (مادة مقدمة) على موقعنا أو
              خدماتنا، أنت تعطينا حقاً غير حصري من دون قابل مادي على أن يكون
              دائماً وقابلاً للنقل ويغطي العالم بأسره ولا يمكن العدول عنه وقابل
              للمنح بالكامل عبر رخصة ثانوية يخولنا باستخدام وإعادة إنتاج وتعديل
              وتحرير وتكييف وترجمة وتوزيع ونشر ونسخ وبث ونقل بأي طريقة أخرى ووضع
              أعمال مستمدة منها والقيام بعرض علناً المادة المقدمة وأدائها وذلك
              في العالم بأسره وعبر أي وسيلة كانت. كما تمنحنا حق استخدام الاسم
              الذي قدمت المادة تحته. توافق على التنازل عن أية حقوق أخلاقية لها
              علاقة بالمادة المقدمة وذلك لأغراض تقديمها ونشرها على الموقع
              والخدمات.
            </p>

            <p>
              لسنا ملزمين وليست علينا أية واجبات مترتبة على هذه الشروط، باستخدام
              أو عرض المادة التي قدمتها. إضافة إلى ذلك، أي استخدام للمادة
              المقدمة من قبلنا لا يؤدي إلى أي واجب علينا بدفع مقابل مادي.
            </p>

            <p>
              أنت مسؤول حصراً عن أية مادة مقدمة على موقعنا أو خدماتنا ولدى
              تقديمها أنت توافق صراحة بألا تنشر على مواقع تواصل اجتماعي أو تقوم
              بتنزيل أو نقل أو توزيع أو تخزين أو إنشاء أو نشر أو التسبب بالنشر
              على موقعنا أو خدماتنا بشكل مباشر أو غير مباشر ما يلي:
            </p>

            <ol>
              <li>
                محتوى غير قانوني أو من باب القدح أو الذم أو مهين لأي دين أو
                مجموعة أخلاقية أو سفيه أو إباحي أو بذيء أو فاسق أو إيحائي أو
                مضايقات أو تهديدات أو تعدٍ على الخصوصية والحقوق العامة أو انتهاك
                أو تحريض أو احتيال أو أي محتوى آخر مستهجن
              </li>

              <li>
                محتوى قد يعتبر أو يشجع على أو يعطي تعليمات للقيام بأعمال جرمية
                أو انتهاك حقوق طرف آخر أو يمكن أن يؤدي إلى مسؤولية قانونية أو
                يخالف أية قوانين محلية أو وطنية أو دولية
              </li>
              <li>
                محتوى قد يتسبب أو يمكن التوقع منطقياً بأن يتسبب بالأذى أو الضرر
                للآخرين أو لنا أو لشركاتنا التابعة أو لموظفينا
              </li>
              <li>محتوى ملك لغيرك من دون إذن صريح من صاحب هذا المحتوى</li>
              <li>
                محتوى لا ينتهك أية براءة اختراع أو علامة تجارية أو حقوق مؤلف أو
                غيرها من الحقوق الفكرية والملكية لأي طرف
              </li>
              <li>
                محتوى ينتحل شخصية أي شخص أو كيان أو يسيء تمثيل علاقتك بذلك الشخص
                أو الكيان أو بنا
              </li>
              <li>
                عروض ترويجية وحملات سياسية وإعلانات ومسابقات وسحوبات بالقرعة
                وإغراءات غير مرغوب بها
              </li>
              <li>
                معلومات خاصة عن أي طرف آخر بما في ذلك، على سبيل المثال لا الحصر،
                الشهرة (اسم العائلة) والعنوان ورقم الهاتف والبريد الإلكتروني
                وأرقام بطاقات الائتمان
              </li>
              <li>
                الفيروسات والبيانات المؤذية أو المخربة أو المحدثة للاضطرابات أو
                المدمرة
              </li>
              <li>
                محتوى أو روابط نرى إنها إما (أ) تخترق البنود أعلاه أو (ب)
                مستهجنة أو (ج) تقيّد أي شخص آخر من استخدام أو الاستمتاع بالموقع
                أو الخدمات أو (د) تعرّضنا أو قد تعرّضنا أو أي من شركاتنا التابعة
                أو أي مستخدم للأذى أو المسؤولية القانونية.
              </li>
            </ol>

            <p>
              علاوة على ذلك، أنت تضمن أن المادة المقدمة على موقعنا أو خدماتنا هي
              عملك الأصلي وأنه يحق لك أن توفرها لنا وأنها ليست غير قانونية أو
              غير مشروعة وأنها لن وتقوم الآن أو في المستقبل بمضايقة أو إلحاق
              الأذى بأي شخص ولن يكون فيها أي محتوى بذيء أو جارح.
            </p>

            <h2>التعويض </h2>
            <p>
              أنت توافق على دفع تعويض لنا أو لأي من شركاتنا التابعة أو مكاتبنا
              أو مدراءنا أو موظفينا فوراً وعند الطلب مقابل أي شكوى أو مسؤولية أو
              أعطال أو تكاليف أو نفقات بما في ذلك الرسوم القانونية المتأتية عن
              خرق لهذه الشروط من قبلك أو عن أية مسؤولية أخرى نتيجة استخدامك
              للموقع و/أو الخدمات.
            </p>

            <p>
              قد يؤدي اختراقك للشروط لدى استخدامك للموقع و/أو الخدمات، من بين
              أمور أخرى، إلى إلغاء أو تعليق حقوقك بالاستخدام. من باب التعاون مع
              الطلبات الحكومية المشروعة، والاستدعاءات والأوامر القضائية، ولحماية
              المستخدمين الآخرين، ونظمنا وعملائنا، أو لضمان نزاهة وحسن سير
              أعمالنا ونظمنا، قد نلج إلى أو نفصح عن أية معلومات عندما نرى أن ذلك
              ضرورياً أو مناسباً، على سبيل المثال لا الحصر، معلومات المستخدم على
              حسابه (أي الاسم والبريد الإلكتروني الخ) وعنوان بروتوكول الإنترنت
              IP ومعلومات حركة مرور البيانات وتاريخ الاستخدام والمواد المقدمة.
              يتخذ حقنا في الإفصاح عن تلك المعلومات أولوية على أية شروط أخرى في
              سياسة الكوكيز والخصوصية الخاصة بالموقع.
            </p>

            <h2>المسؤولية </h2>
            <p>
              نحن خاضعون في كل وقت لأية مسؤولية أو مسؤولية قانونية لا يمكن
              استبعادها وحدها بموجب القانون أما بالنسبة إلى استخدامك للموقع
              والخدمات، فنحن وشركاتنا التابعة ومكاتبنا ومدراءنا وموظفونا وأصحاب
              الأسهم لدينا ووكلاؤنا ووكلاؤهم، لا نقبل أية مسؤولية عن أي مبلغ أو
              أية خسارة أو ضرر من أي نوع لك أو لطرف ثالث (بما في ذلك على سبيل
              المثال لا الحصر، أية خسائر أو أضرار مباشرة أو غير مباشرة أو
              تأديبية أو هامة) أو أية خسارة في الدخل أو الأرباح أو النوايا
              الحسنة أن البيانات أو العقود أو خسائر أو أضرار متأتية عن أو على
              علاقة بأي طريقة ما بانقطاع الأعمال والمسؤولية التقصيرية (على سبيل
              المثال لا الحصر الإهمال، في العقد أو غير ذلك) في ما يتعلق
              باستخدامك أو عدم قدرتك على استخدام أو نتيجة لاستخدامك الموقع أو
              الخدمات، بما في ذلك على سبيل المثال لا الحصر أية خسائر أو أضرار
              بسبب فيروسات انتقلت إلى جهاز الكومبيوتر الخاص بك أو أجهزتك أو
              برامجك أو بياناتك أو ممتلكاتك بسبب نفاذك إلى واستخدام أو تصفح
              الموقع أو الخدمات.
            </p>

            <h2>القانون الحاكم</h2>
            <p>
              سوف تحكم هذه الشروط قوانين مركز دبي المالي العالمي في دولة
              الإمارات العربية المتحدة. ويخضع أي خلاف حول هذه الشروط أو الموقع
              أو الخدمات حصرياً إلى التخصص القضائي في محاكم مركز دبي المالي
              العالمي في الإمارات العربية المتحدة.
            </p>

            <p>
              إذا ما حددت أي محكمة أو جهة ناظمة في المنطقة القضائية المعنية أن
              إحدى أحكام هذه الشروط باطلة أو غير قابلة للإنفاذ أو أمرت بحذفها أو
              إزالتها من الشروط سوف تبقى سائر الشروط الأخرى حيّز التنفيذ.
            </p>

            <h2>الاتصال</h2>
            <p>
              إذا كانت لديك أية أسئلة أو استفسارات حول المادة على الموقع أو
              الخدمات، يرجى الاتصال بنا بالرجوع إلى بيانات الاتصال تحت خانة
              "اتصل بنا" على الموقع.
            </p>
          </div>
        </Layout>
      )}
    </Location>
  )
}

export default TermsConditions

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
